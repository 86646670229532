import styled from 'styled-components';
import {Link} from 'gatsby';

export const HeroContainer = styled.section`
	width: 100%;
	height: ${(props) => {
		return props.page !== 'home' ? `calc(100vh - 200px)` : `auto`;
	}};
	margin: 0;
	padding: ${(props) => {
		return props.page !== 'home' ? `0` : `6rem 0`;
	}};
	margin-top: 100px;
	background-color: #fff;
	@media (max-width: 1200px) {
		height: auto;
		margin-top: 0;
	}
`;
export const HeroInner = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1200px;
	margin: 0 auto;
	height: 100%;
	@media (max-width: 1200px) {
		flex-wrap: wrap;
		justify-content: center;
	}
`;

export const HeroContent = styled.div`
	width: 50%;
	@media (max-width: 1200px) {
		width: 60%;
		padding: 1rem;
		text-align: center;
	}
	@media (max-width: 1200px) {
		width: 100%;
		padding: 1rem;
	}
	h1,
	p {
		color: ${({theme}) => theme.colors.primary};
		padding-right: 2rem;
		@media (max-width: 640px) {
			padding-right: 0;
		}
	}
	h1 {
		${(props) => (props.page === 'home' ? 'font-size: 3rem;' : '')};
	}
	p {
		line-height: 1.75;
		${(props) => (props.page === 'home' ? 'font-size: 1.5rem;' : '')};
	}
`;
export const HeroImage = styled.div`
	width: 50%;
	height: 100%;
	img {
		height: 100%;
		object-fit: contain;
	}
	@media (max-width: 1200px) {
		width: 60%;
		padding: 1rem;
	}
	@media (max-width: 828px) {
		width: 100%;
		padding: 1rem;
	}
`;

export const ContactButton = styled(Link)`
	display: inline-block;
	padding: 0.75rem 1.5rem;
	background-color: ${({theme}) => theme.colors.secondary};
	border-radius: 0.25rem;
	border: 3px solid ${({theme}) => theme.colors.secondary};
	margin-bottom: 3rem;
	margin-top: 1rem;
	color: #fff;
	text-decoration: none;
	font-size: 1.25rem;
	font-weight: 600;
	transition: all 0.2s ease-in-out;
	&:hover {
		background-color: #fff;
		color: ${({theme}) => theme.colors.secondary};
	}
`;

export const OrderSearch = styled.div`
	width: 45%;
	padding: 1.75rem 2rem 2.25rem;
	background-color: ${({theme}) => theme.colors.primary};
	border-radius: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
	@media (max-width: 1024px) {
		width: 60%;
	}
	@media (max-width: 768px) {
		width: 100%;
		margin: 0 1rem;
	}
	h2 {
		margin: 0;
		margin-bottom: 1.5rem;
		line-height: 1;
		font-size: 1.875rem;
		color: #fff;
	}
`;
