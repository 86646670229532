import React, {useState, useEffect} from 'react';
import {graphql} from 'gatsby';
import styled from 'styled-components';
import get from 'lodash/get';

import Master from '../templates/Master.jsx';
import SEO from '../components/shared/seo';
import Hero from '../components/shared/Hero';
import SearchButton from '../components/shared/SearchButton';
// import RichText from '../components/shared/RichText';

const Container = styled.section`
	/* ${({theme}) => theme.container}
	padding: 3rem 1rem; */
`;

const Index = ({data: {kontentItemPage: data}}) => {
	const [width, setWidth] = useState('');

	useEffect(() => {
		setWidth(window.innerWidth);
		const handleResize = () => setWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});
	const props = {
		title: get(data, 'elements.title.value'),
		hero: get(data, 'elements.hero.value'),
		content: get(data, 'elements.content.value'),
	};
	return (
		<Master>
			<SEO title={props.title} />
			{(() => {
				if (width <= 1024) {
					return (
						<section>
							<SearchButton />
						</section>
					);
				}
			})()}
			<Hero linkedItem={props.hero} page={data.system.codename} />
			<Container>
				{/* <RichText
					content={props.content}
					images={props.images}
					links={props.links}
					linkedItems={props.linkedItems}
				/> */}
			</Container>
		</Master>
	);
};

export default Index;

export const query = graphql`
	query($slug: String!) {
		kontentItemPage(elements: {slug: {value: {eq: $slug}}}) {
			system {
				codename
			}
			elements {
				title {
					value
				}
				slug {
					value
				}
				content {
					links {
						codename
						link_id
						type
						url_slug
					}
					images {
						image_id
						url
						description
					}
					value
				}
				hero {
					value {
						...kontentItemSectionHeroFragment
					}
				}
			}
		}
	}
`;
