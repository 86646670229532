import React from 'react';
import {graphql} from 'gatsby';

import {
	HeroContainer,
	HeroInner,
	HeroContent,
	HeroImage,
	OrderSearch,
	ContactButton,
} from './HeroStyles';

import SearchButton from './SearchButton';

const Hero = ({linkedItem, page}) => {
	console.log(linkedItem, page);
	const content = linkedItem[0].elements;
	const props = {
		image: content.image.value[0]?.url,
		copy: content.copy.value,
		heading: content.heading.value,
	};
	return (
		<HeroContainer page={page}>
			<HeroInner>
				<HeroContent page={page}>
					<h1>{props.heading}</h1>
					{props.copy ? <p>{props.copy}</p> : null}
					{page === 'about' ? (
						<ContactButton to="/contact">
							Contact Lora
						</ContactButton>
					) : null}
				</HeroContent>
				{page !== 'home' ? (
					<HeroImage>
						<img src={props.image} />
					</HeroImage>
				) : (
					<OrderSearch>
						<h2>Order your photos</h2>
						<SearchButton />
					</OrderSearch>
				)}
			</HeroInner>
		</HeroContainer>
	);
};

export default Hero;

export const kontentItemSectionHeroFragment = graphql`
	fragment kontentItemSectionHeroFragment on kontent_item_section___hero {
		id
		elements {
			background_image {
				value {
					url
				}
			}
			copy {
				value
			}
			heading {
				value
			}
			image {
				value {
					url
				}
			}
		}
	}
`;
